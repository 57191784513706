var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-Create",attrs:{"title":("" + (_vm.info.id ? 'Actualizar marca' : 'Crear Marca')),"cancel-title":"Cancelar","scrollable":"","size":"sm","no-close-on-backdrop":"","no-close-on-esc":""},on:{"hidden":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancelar")]),(_vm.info.id)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.updateProveedor}},[_vm._v("Actualizar")]):_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.registerBrands}},[_vm._v("Guardar")])]},proxy:true}])},[_c('div',[_c('ValidationObserver',{ref:"formProvider"},[_c('div',{staticClass:"containerProviders"},[_c('div',[_c('div',[_c('ValidationProvider',{attrs:{"rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors[0] ? false : null}},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nombre de la marca")]),_c('b-form-input',{attrs:{"id":"name","state":errors[0] ? false : null,"type":"text","placeholder":"Nombre de la marca"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('ValidationProvider',{attrs:{"rules":"max:250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors[0] ? false : null}},[_c('label',{attrs:{"for":"name"}},[_vm._v("Descripcion")]),_c('b-form-textarea',{attrs:{"id":"textarea","state":errors[0] ? false : null,"placeholder":"descripcion...","rows":"3"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])}),_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"País"}},[_c('v-select',{attrs:{"label":"name","options":_vm.countries,"reduce":function (opt) { return opt.id; },"placeholder":"Seleccionar país"},on:{"input":function($event){;(_vm.form.department = null), (_vm.form.province = null), (_vm.form.district = null)}},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])}),(_vm.form.country == _vm.peru_id)?_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Departamento"}},[_c('v-select',{attrs:{"label":"name","options":_vm.departments,"reduce":function (opt) { return opt.id; },"placeholder":"Seleccionar departamento"},on:{"input":function($event){;(_vm.form.province = null), (_vm.form.district = null)}},model:{value:(_vm.form.department),callback:function ($$v) {_vm.$set(_vm.form, "department", $$v)},expression:"form.department"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,false,2319294502)}):_vm._e(),(_vm.form.country == _vm.peru_id)?_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Provincia"}},[_c('v-select',{attrs:{"label":"name","options":_vm.provinces,"reduce":function (opt) { return opt.id; },"placeholder":"Seleccionar provincia"},on:{"input":function($event){_vm.form.district = null}},model:{value:(_vm.form.province),callback:function ($$v) {_vm.$set(_vm.form, "province", $$v)},expression:"form.province"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,false,3097171829)}):_vm._e(),(_vm.form.country == _vm.peru_id)?_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Distrito"}},[_c('v-select',{attrs:{"label":"name","options":_vm.districts,"reduce":function (opt) { return opt.id; },"placeholder":"Seleccionar distrito"},model:{value:(_vm.form.district),callback:function ($$v) {_vm.$set(_vm.form, "district", $$v)},expression:"form.district"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,false,1769347293)}):_vm._e(),_c('div',[_c('b-form-group',{attrs:{"label":"Imagen"}},[_c('b-avatar',{ref:"previewEl",staticClass:"clickable text-dark w-100",staticStyle:{"border":"2px dashed var(--dark)"},attrs:{"icon":"image","square":"","rounded":"lg","variant":"light","src":_vm.form.logotype,"size":_vm.heightImage},on:{"click":function($event){return _vm.$refs.fileInput.click()}},model:{value:(_vm.form.logotype),callback:function ($$v) {_vm.$set(_vm.form, "logotype", $$v)},expression:"form.logotype"}}),_c('div',{staticClass:"d-flex justify-content-end",staticStyle:{"margin-top":"5px"}},[_c('span',[_c('feather-icon',{staticClass:"cursor-pointer text-danger mr-50",attrs:{"size":"22","icon":"XOctagonIcon"},on:{"click":function($event){return _vm.deletePhoto()}}}),_c('feather-icon',{staticClass:"cursor-pointer text-primary",attrs:{"size":"22","icon":"UploadIcon"},on:{"click":function($event){return _vm.$refs.fileInput.click()}}})],1)]),_c('input',{ref:"fileInput",staticClass:"d-none",attrs:{"type":"file","accept":".jpeg,.jpg,.png,.heif,.heic"},on:{"input":_vm.pickFile}})],1)],1)],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }