<template>
	<b-modal
		ref="modal-Create"
		@hidden="$emit('close')"
		:title="`${info.id ? 'Actualizar marca' : 'Crear Marca'}`"
		cancel-title="Cancelar"
		scrollable
		size="sm"
		no-close-on-backdrop
		no-close-on-esc
	>
		<div>
			<ValidationObserver ref="formProvider">
				<div class="containerProviders">
					<div>
						<div>
							<ValidationProvider v-slot="{ errors }" rules="required|max:100">
								<b-form-group :state="errors[0] ? false : null">
									<label for="name">Nombre de la marca</label>
									<b-form-input
										v-model="form.name"
										id="name"
										:state="errors[0] ? false : null"
										type="text"
										placeholder="Nombre de la marca"
									/>
									<small v-if="errors.length > 0" class="text-danger">
										{{ errors[0] }}
									</small>
								</b-form-group>
							</ValidationProvider>
						</div>
						<ValidationProvider v-slot="{ errors }" rules="max:250">
							<b-form-group :state="errors[0] ? false : null">
								<label for="name">Descripcion</label>
								<b-form-textarea
									v-model="form.description"
									id="textarea"
									:state="errors[0] ? false : null"
									placeholder="descripcion..."
									rows="3"
								></b-form-textarea>
								<small v-if="errors.length > 0" class="text-danger">
									{{ errors[0] }}
								</small>
							</b-form-group>
						</ValidationProvider>
						<ValidationProvider v-slot="{ errors }">
							<b-form-group label="País">
								<v-select
									label="name"
									:options="countries"
									:reduce="(opt) => opt.id"
									v-model="form.country"
									placeholder="Seleccionar país"
									@input=";(form.department = null), (form.province = null), (form.district = null)"
								/>
								<small v-if="errors.length > 0" class="text-danger">
									{{ errors[0] }}
								</small>
							</b-form-group>
						</ValidationProvider>
						<ValidationProvider v-slot="{ errors }" v-if="form.country == peru_id">
							<b-form-group label="Departamento">
								<v-select
									label="name"
									:options="departments"
									:reduce="(opt) => opt.id"
									v-model="form.department"
									placeholder="Seleccionar departamento"
									@input=";(form.province = null), (form.district = null)"
								/>
								<small v-if="errors.length > 0" class="text-danger">
									{{ errors[0] }}
								</small>
							</b-form-group>
						</ValidationProvider>
						<ValidationProvider v-slot="{ errors }" v-if="form.country == peru_id">
							<b-form-group label="Provincia">
								<v-select
									label="name"
									:options="provinces"
									:reduce="(opt) => opt.id"
									v-model="form.province"
									placeholder="Seleccionar provincia"
									@input="form.district = null"
								/>
								<small v-if="errors.length > 0" class="text-danger">
									{{ errors[0] }}
								</small>
							</b-form-group>
						</ValidationProvider>
						<ValidationProvider v-slot="{ errors }" v-if="form.country == peru_id">
							<b-form-group label="Distrito">
								<v-select
									label="name"
									:options="districts"
									:reduce="(opt) => opt.id"
									v-model="form.district"
									placeholder="Seleccionar distrito"
								/>
								<small v-if="errors.length > 0" class="text-danger">
									{{ errors[0] }}
								</small>
							</b-form-group>
						</ValidationProvider>
						<!-- <b-form-group v-if="info.id">
							<label for="name">Estado</label>
							<v-select
								id="type_doc"
								:clearable="false"
								label="name"
								:options="arrayStatus"
								v-model="form.status"
							/>
						</b-form-group> -->
						<div>
							<b-form-group label="Imagen">
								<b-avatar
									v-model="form.logotype"
									class="clickable text-dark w-100"
									style="border: 2px dashed var(--dark)"
									ref="previewEl"
									icon="image"
									square
									rounded="lg"
									variant="light"
									:src="form.logotype"
									:size="heightImage"
									@click="$refs.fileInput.click()"
								/>
								<div class="d-flex justify-content-end" style="margin-top: 5px">
									<span>
										<feather-icon
											size="22"
											icon="XOctagonIcon"
											class="cursor-pointer text-danger mr-50"
											@click="deletePhoto()"
										/>
										<feather-icon
											size="22"
											icon="UploadIcon"
											class="cursor-pointer text-primary"
											@click="$refs.fileInput.click()"
										/>
									</span>
								</div>
								<input
									ref="fileInput"
									type="file"
									accept=".jpeg,.jpg,.png,.heif,.heic"
									@input="pickFile"
									class="d-none"
								/>
							</b-form-group>
						</div>
					</div>
				</div>
			</ValidationObserver>
		</div>
		<template #modal-footer>
			<b-button variant="danger" @click="$emit('close')">Cancelar</b-button>
			<b-button v-if="info.id" variant="primary" @click="updateProveedor">Actualizar</b-button>
			<b-button v-else variant="primary" @click="registerBrands">Guardar</b-button>
		</template>
	</b-modal>
</template>
<script>
import modalMixin from "@/mixins/modal.js"
import Ripple from "vue-ripple-directive"
import BrandsService from "@/views/brain/administrative/views/branch/services/brands.service.js"
import { ValidationProvider } from "vee-validate"
import CommonsService from "@/services/commons.service.js"
export default {
	components: {
		ValidationProvider,
	},
	props: {
		info: {
			required: true,
		},
	},
	directives: { Ripple },
	mixins: [modalMixin],

	data() {
		return {
			isAdd: false,
			form: {
				id: null,
				name: null,
				description: null,
				logotype: null,
				status: null,
				file: null,
				department: null,
				province: null,
				district: null,
				country: null,
			},
			arrayStatus: ["active", "inactive"],
			countries: [],
			departments: [],
			provinces: [],
			districts: [],
			peru_id: null,
		}
	},

	async mounted() {
		await this.getCountries()
		this.peru_id = this.countries.find((country) => country.code === "PE").id
		if (this.info.id == null) {
			this.form.country = this.peru_id
		}
		this.getDepartments()
		this.toggleModal("modal-Create")
	},

	created() {
		if (this.info.id != null) {
			this.form.id = this.info.id
			this.form.name = this.info.name
			if (this.info.description) {
				this.form.description = this.info.description
			}
			this.form.logotype = this.info.logotype
			this.form.status = this.info.status
			this.form.country = this.info.country_id
			this.form.department = this.info.department_id
			this.form.province = this.info.province_id
			this.form.district = this.info.district_id
		}
	},

	computed: {
		heightImage() {
			return ["md", "lg", "xl"].includes(this.currentBreakPoint) ? "15rem" : "18rem"
		},
	},
	methods: {
		async getCountries() {
			try {
				const { data } = await CommonsService.getCountries()
				this.countries = data
			} catch (error) {
				console.log(error)
			}
		},
		async getDepartments() {
			try {
				const { data } = await CommonsService.getDepartments()
				this.departments = data
			} catch (error) {
				console.log(error)
			}
		},
		async getProvinces(department_id) {
			try {
				const { data } = await CommonsService.getProvinces(department_id)
				this.provinces = data
			} catch (error) {
				console.log(error)
			}
		},
		async getDistricts(province_id) {
			try {
				const { data } = await CommonsService.getDistricts(province_id)
				this.districts = data
			} catch (error) {
				console.log(error)
			}
		},
		deletePhoto() {
			this.form.logotype = null
			this.form.file = null
		},
		async registerBrands() {
			const validate = await this.$refs.formProvider.validate()
			if (!validate) return

			const confirm = await this.showConfirmSwal({
				text: "Registrar Marca",
			})
			if (!confirm.isConfirmed) return

			try {
				this.isPreloading()
				const formData = new FormData()
				formData.append("name", this.form.name)
				formData.append("description", this.form.description)
				formData.append("logotype", this.form.file)
				if (this.form.country) {
					formData.append("country_id", this.form.country)
				}
				if (this.form.department) {
					formData.append("department_id", this.form.department)
				}
				if (this.form.province) {
					formData.append("province_id", this.form.province)
				}
				if (this.form.district) {
					formData.append("district_id", this.form.district)
				}
				const registered = await BrandsService.addBrands(formData)
				this.showSuccessToast(registered.data.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async updateProveedor() {
			const validate = await this.$refs.formProvider.validate()
			if (!validate) return
			const confirm = await this.showConfirmSwal({
				text: "Actualizar Marca",
			})
			if (!confirm.isConfirmed) return

			try {
				this.isPreloading()
				const formData = new FormData()
				formData.append("name", this.form.name)
				formData.append("description", this.form.description)
				formData.append("logotype", this.form.file)
				formData.append("status", this.form.status)
				if (this.form.country) {
					formData.append("country_id", this.form.country)
				}
				if (this.form.department) {
					formData.append("department_id", this.form.department)
				}
				if (this.form.province) {
					formData.append("province_id", this.form.province)
				}
				if (this.form.district) {
					formData.append("district_id", this.form.district)
				}
				const registered = await BrandsService.updateBrands(this.form.id, formData)
				this.showSuccessToast(registered.data.message)
				this.$emit("registered")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		pickFile() {
			let input = this.$refs.fileInput
			let file = input.files

			const typeOfFile = file[0] && file[0].name.split(".")[file[0].name.split(".").length - 1]
			if (!["jpeg", "jpg", "png"].includes(typeOfFile)) {
				this.showToast(
					"warning",
					"top-right",
					"Formato de archivo no valido",
					"CheckIcon",
					"Los formatos aceptados son .jpeg, .jpg y .png ."
				)
				return
			}
			if (typeOfFile === "heic" || typeOfFile === "heif") {
				this.isPreloading()
				this.showToast(
					"warning",
					"top-right",
					"Cargando imagen, espere un momento",
					"CheckIcon",
					"El formato HEIF o HEIC no es soportado, por ello lo puede tardar un momento."
				)
				heic2any({ blob: file[0], toType: "image/jpg", quality: 1 }).then((newImage) => {
					const url = URL.createObjectURL(newImage)
					let newFile = new File([newImage], "heic" + ".jpg", {
						type: "image/jpeg",
						lastModified: new Date().getTime(),
					})
					this.form.file = newFile
					this.form.logotype = url
					this.isPreloading(false)
				})
			} else {
				this.form.file = file[0]
				if (file && file[0]) {
					let reader = new FileReader()
					reader.onload = (e) => {
						this.form.logotype = e.target.result
					}
					reader.readAsDataURL(file[0])
					this.form.file = file[0]
				}
			}
		},
	},
	watch: {
		"form.department": function (val) {
			if (val) {
				this.getProvinces(val)
			}
		},
		"form.province": function (val) {
			console.log("uwu")
			console.log(val)
			if (val) {
				this.getDistricts(val)
			}
		},
	},
}
</script>
<style scoped>
.containerProviders {
	display: grid;
	grid-template-columns: 1fr;
	width: 100%;
	gap: 2rem;
}

@media screen and (max-width: 768px) {
	.containerProviders {
		grid-template-columns: 1fr; /* Cambia a una sola columna en dispositivos con un ancho máximo de 768px */
	}
}

.labelCode {
	text-align: start !important;
}

.containerLabel {
	display: grid !important;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	place-items: center;
	gap: 1rem;
	width: 94%;
}

@media screen and (max-width: 768px) {
	.containerLabel {
		gap: 0rem;
		width: auto;
	}
}

.container-input {
	gap: 1rem;
}

@media screen and (max-width: 768px) {
	.container-input {
		gap: 0rem;
	}
}

.custom-code {
	width: 100%;
	min-width: 120px;
}
@media screen and (max-width: 768px) {
	.custom-code {
		width: 100px;
		min-width: 100px;
		margin-left: 5px;
		margin-right: 5px;
	}
}

/* .containerInput {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 1rem;
} */

/* .container-contact {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;

} */

@media screen and (max-width: 768px) {
	.custom-scroll {
		overflow-y: scroll;
		min-height: auto;
		max-height: 300px;
	}
}
</style>
